import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Sponsors = () => {
  const data = useStaticQuery(graphql`
    query {
      vwLogo: file(relativePath: { eq: "logos/vwst.png" }) {
        childImageSharp {
          fixed(quality: 100, height: 75) { 
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      lmuLogo: file(relativePath: { eq: "logos/lmu.png" }) {
        childImageSharp {
          fixed(quality: 100, height: 75) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      elfgenpickLogo: file(relativePath: { eq: "logos/elfgenpick.png" }) {
        childImageSharp {
          fixed(quality: 100, width: 300) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      milkroomLogo: file(relativePath: { eq: "logos/milkroomstudios.png" }) {
        childImageSharp {
          fixed(quality: 100, height: 75) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <div>
      <h2>Sponsoren</h2>
      <p>
        Die Herstellung von <b className="bronzeon">BRONZEON</b> wurde durch die
        Volkswagen Stiftung gefördert.
        <br />
        Dank der großzügigen und zum Teil unentgeltlichen Unterstützung der
        Firmen elfgenpick (Design und Public Relation) sowie Milkroom Studios
        (Game Design und Programmierung) konnte das Spiel realisiert werden.
      </p>
      <br />
      <div id="sponsors" className="sponsorimages">
        <div>
          <Img
            fixed={data.vwLogo.childImageSharp.fixed}
            alt="Volkswagen Stiftung Logo"
          />
        </div>
        <div>
          <Img fixed={data.lmuLogo.childImageSharp.fixed} alt="LMU Logo" />
        </div>
        <div>
          <Img
            fixed={data.elfgenpickLogo.childImageSharp.fixed}
            alt="elfgenpick logo"
          />
        </div>
        <div>
          <Img
            fixed={data.milkroomLogo.childImageSharp.fixed}
            alt="Milkroom Studios Logo"
          />
        </div>
      </div>
    </div>
  )
}

export default Sponsors
